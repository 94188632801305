import React from 'react';

export default function Hero() {
  return (
    <section className='bg-green-50 shadow-inner' data-testid='hero-component'>
      <div className='mx-auto max-w-screen-xl px-4 py-16 sm:px-6 sm:py-12 lg:px-8 lg:py-16'>
        <div className='grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16'>
          <div className='relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full'>
            <img
              alt=''
              src='./tejal.jpg'
              className='absolute inset-0 h-full w-full object-cover'
            />
          </div>

          <div className='lg:py-24'>
            <h2 className='text-3xl font-bold sm:text-4xl'>
              Welcome to my online space!
            </h2>

            <p className='mt-4 text-gray-600'>
              I&apos;m Tejal, a Talent, OD & DEI Manager with over a decade of
              experience in empowering people and organizations. My journey in
              HR has been driven by a passion for developing talent, creating
              inclusive environments, and driving strategic initiatives that
              lead to lasting success. As a certified trainer in leadership and
              DEI, I&apos;ve been fortunate to lead transformational projects
              that enhance both organizational culture and business outcomes.
            </p>

            <h2 className='mt-4 text-1xl font-bold sm:text-2xl'>
              Ready to transform your organization&apos;s talent and DEI
              strategy?
            </h2>

            <p className='mt-4 text-gray-600'>
              Whether you’re looking to collaborate or just want to chat about
              the latest in HR, I’d love to hear from you.
            </p>

            <a
              className='mt-4 group relative inline-block text-sm font-medium text-green-600 focus:outline-none focus:ring active:text-green-500'
              href='https://calendly.com/tejalvyas/30min'
              target='_blank'
              rel='noreferrer'
            >
              <span className='absolute inset-0 translate-x-0.5 translate-y-0.5 bg-green-600 transition-transform group-hover:translate-x-0 group-hover:translate-y-0' />
              <span className='relative block border border-current bg-white px-8 py-3'>
                Let’s connect
              </span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
