import React from 'react';

const workExperience = [
  {
    company: 'Tata Technologies',
    role: 'Sr. Lead & Manager - Talent Management, OD and Diversity',
    duration: 'Aug 2024 - Present · 6 mos',
    responsibilities:
      'Leading the People Operations team and implementing diversity, equity, and inclusion (DEI) initiatives across departments. Developed strategies for improving diversity goals, streamlined HR processes, and managed HRIS for onboarding new hires. Collaborated with internal stakeholders and HR Business Partners for data-driven HR decisions and DEI efforts.',
    current: true,
  },
  {
    company: 'Tata Technologies',
    role: 'Talent Management and DEI Specialist',
    duration: 'May 2023 - Aug 2024 · 1 yr 4 mos',
    responsibilities:
      'Managed and led the People Operations team, overseeing operational and strategic initiatives. Implemented DEI projects across geographies, developed strategies for diversity goals, and enhanced HR processes. Worked with HR Business Partners on data-driven HR reports and collaborated with various teams to increase DEI visibility.',
    current: false,
  },
  {
    company: 'ŠKODA AUTO Volkswagen India Private Limited',
    role: 'Assistant Manager Human Resources',
    duration: 'Apr 2019 - May 2023 · 4 yrs 2 mos',
    responsibilities:
      'Developed and executed competency frameworks and leadership development programs. Led employee engagement initiatives, corporate diversity projects, and managed HR operations including recruitment and talent assessments. Implemented cost-effective programs to boost morale and performance.',
    current: false,
  },
  {
    company: 'SKODA India',
    role: 'Senior Human Resources Officer',
    duration: 'Aug 2017 - Apr 2019 · 1 yr 9 mos',
    responsibilities:
      'Utilized data analysis for workforce planning and conducted competency assessments. Trained leaders and facilitated promotion cycles while managing employee engagement activities. Oversaw international projects and coordinated training sessions for employee development across offices.',
    current: false,
  },
  {
    company: 'Sanjeev Auto Parts Manufacturers Pvt. Ltd.',
    role: 'HR Specialist',
    duration: 'Jun 2015 - Aug 2017 · 2 yrs 3 mos',
    responsibilities:
      'Managed strategic HR projects focused on improving employee relations and retention. Conducted employee satisfaction surveys and engagement activities, implemented HR systems, and handled performance management and HRIS to foster a positive work environment.',
    current: false,
  },
  {
    company: 'Siemens',
    role: 'Management Trainee',
    duration: 'Jun 2014 - Jul 2014 · 2 mos',
    responsibilities:
      'Analyzed industrial relations records, contributed to the Organizational Health Center Department, and led employee engagement initiatives. Assisted in policy implementation and coordinated CSR activities while supporting HR efforts in improving organizational well-being.',
    current: false,
  },
  {
    company: 'Lotus Business Management & Consultancy Ltd',
    role: 'Admin Coordinator in Marketing Training',
    duration: 'Jul 2013 - Sep 2013 · 3 mos',
    responsibilities:
      'Handled telemarketing efforts targeted at HR professionals for business trainings in the Aurangabad region. Focused on improving interpersonal skills and effective communication.',
    current: false,
  },
];

export default function Timeline() {
  return (
    <div className='bg-white py-16 sm:py-16' data-testid='timeline-component'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-xl text-center'>
          <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            Professional Journey
          </p>
        </div>
        <ol className='relative border-s border-gray-200 mt-16'>
          {workExperience.map((experience) => (
            <li className='mb-10 ms-6' key={experience.role}>
              <span className='absolute flex items-center justify-center w-6 h-6 bg-gray-100 rounded-full -start-3 ring-8 ring-white'>
                <svg
                  className='w-2.5 h-2.5 text-gray-500'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                >
                  <path d='M19.728 10.686c-2.38 2.256-6.153 3.381-9.875 3.381-3.722 0-7.4-1.126-9.571-3.371L0 10.437V18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-7.6l-.272.286Z' />
                  <path d='m.135 7.847 1.542 1.417c3.6 3.712 12.747 3.7 16.635.01L19.605 7.9A.98.98 0 0 1 20 7.652V6a2 2 0 0 0-2-2h-3V3a3 3 0 0 0-3-3H8a3 3 0 0 0-3 3v1H2a2 2 0 0 0-2 2v1.765c.047.024.092.051.135.082ZM10 10.25a1.25 1.25 0 1 1 0-2.5 1.25 1.25 0 0 1 0 2.5ZM7 3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1H7V3Z' />
                </svg>
              </span>
              <h3 className='flex items-center mb-1 text-lg font-semibold text-gray-900'>
                {experience.company}
                {experience.current && (
                  <span className='bg-gray-100 text-blue-600 text-sm font-medium me-2 px-2.5 py-0.5 rounded  ms-3'>
                    Current
                  </span>
                )}
              </h3>
              <time className='block mb-2 text-sm font-normal leading-none text-green-600 '>
                {experience.role}
              </time>
              <time className='block mb-2 text-sm font-normal leading-none text-gray-400 '>
                {experience.duration}
              </time>
              <p className='mb-4 text-base font-normal text-gray-500 '>
                {experience.responsibilities}
              </p>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}
